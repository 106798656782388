@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

.container {
  @media(min-width: 1200px) {
    max-width: 1170px;
  }
}

.slick-slider {
  margin: 0 -4px;
}

.slick-slide {
  padding: 0 4px;

}

.section-welcome {
  background-color: color(typography, 1);
  padding: 42px 0;
  .container {
    @media(min-width: 1200px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .image-wrap {
    @media(min-width: 1200px) {
      width: 620px;
    }
    img {
      @media(min-width: 1200px) {
        max-width: initial;
        margin-left: -28px;
      }
    }
  }
  .title-wrap {

    h3 {
      position: relative;
      color: color(typography, 5);

    }
  }
  .content-wrap {
    line-height: 30px;
    @media(max-width: 991px) {
      margin-top: 30px;
    }
  }

  h3 {
    font-size: 20px;
  }

  p {
    span,
    a {
      color: color(typography, 5);
    }
  }

}

.section-carousel {
  background-color: color(typography, 1);
  padding: 25px 0 40px;

  .container {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
  }

}

.slide {
  &-slider {
    .slick {
      &-arrow {
        height: 75px;
        width: 75px;
        background-size: contain;

        z-index: 10;
        background-repeat: no-repeat;
        background-position: center;

        &:before {
          content: '';
        }
      }

      &-prev {
        background-image: url(../images/arrow-prev-white.png);
        left: 15px;
      }

      &-next {
        background-image: url(../images/arrow-next-white.png);
        right: 15px;
      }
    }
  }

  &-wrap {
    position: relative;
    overflow: hidden;

    // max-width: 330px;
    // margin: 0 auto;
    .overlay {
      background-color: rgba(color(typography, 9), .3);
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &-image {
    img {
      width: 100%;
    }
  }

}

.section-crossroad {
  background-color: color(typography, 8);
  
  background-repeat: repeat-y;
  background-position-x: 52%;
  background-size: contain;
  padding-bottom: 50px;
  @media(min-width: 992px) {
    // background-image: url('../images/road.png');
  }
  // border-left: 20px solid color(typography, 1);
  .crossroad {
    &-choose {
      h4 {
        font-size: 18px;
        font-weight: 600;
        color: color(typography, 5);
        letter-spacing: -0.055rem;
      }
      ul {
        li {
          position: relative;
          padding-left: 20px;
          &:before {
            background-color: #596ca3;
            content: '';
            height: 10px;
            width: 10px;
            position: absolute;
            left: 0;
            top: 8px;
          }
          &:not(:last-child) {
            margin-bottom: 10px;
            
          }
        }
      }
    }
    &-order {
      font-size: 18px;
      font-weight: 600;
      color: color(typography, 7);
      @media(max-width: 991px) {
        margin-top: 30px;
      }
      h2 {
        color: color(typography, 5);
        font-weight: 600;
        font-size: 20px;
        
      }
      text-align: center;
      img {
        margin: 20px auto;
      }
      .btn {
        min-width: 215px;
        font-size: 18px;
      }
    }
  }
}

.testimonial {
  padding-top: 23px;
  padding-bottom: 33px;
  .title-wrap {
    h2 {
      font-size: 20px;
      color: color(typography, 7);
      position: relative;
      &:before,
      &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
      }
      &:before {
        background-color: color(typography, 5);
        max-width: 340px;
        height: 2px;
        z-index: 1;

      }
      &:after {
        background-color: #b8c5d1;
        height: 1px;
        bottom: 1px;
        
      }
      .icon-testt {
        background-image: url('../images/icon-testimonial.png');
        height: 35px;
        width: 40px;
        display: inline-block;
        background-repeat: no-repeat;
        font-size: 0;
        margin-left: 40px;
        top: -30px;
        position: relative;
      }
    }
  }
  &-more {
    color: color(typography, 7);
    font-weight: 600;
    font-style: normal;
  }
  &-wrapper {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 35px;
    font-style: italic;
  }
  .slick {
    &-arrow {
      height: 40px;
      width: 40px;
      top: 25px;
      &:before {
        content: '';
      }
    }
    &-prev {
      background-image: url('../images/testimonial-prev.png');
      left: -77px;
    }
    &-next {
      background-image: url('../images/testimonial-next.png');
      right: -77px;
    }
   
  }
  .client-name {
    font-size: 15px;
    font-weight: 600;
  }
}
.testimonial-slider {
  .slick {
    &-dots {
      li {
        button {
          border-radius: 50%;
          height: 15px;
          width: 15px;
          &:before {
            content: '';
          }
        }
        &:nth-child(2n+1) {
          button {
            background-color: #ffc907;
          }
        }
        &:nth-child(2n) {
          button {
            background-color: #d2232a;
          }
        }
        &:nth-child(3n) {
          button {
            background-color: color(typography, 7);
          }
        }
      }
    }
  }
}

.section {
  // gallery page
  &-projects {
    padding-top: 60px;
    padding-bottom: 30px;

    .project {
        &__wrap {
            margin-bottom: 30px;

            figure {
                margin-bottom: 0;
                height: 340px;
                background-size: cover;
                background-position: center;

                a {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &__title {
            padding: 10px 10px 0 10px;
            border: 1px solid #ddd;
            text-align: center;

            h3 {
                font-size: 18px;
                font-weight: 600;

                a {
                    color: currentColor;

                    &:hover {
                        color: color(typography, 5);
                    }
                }
            }
        }
    }
}

  // about page
  &-about {
    padding-top: 65px;
    padding-bottom: 30px;
    @media(max-width: 991px) {
      padding-top: 40px;
      padding-bottom: 0;
    }
    .row {
      [class*="col-"] {
        &:nth-child(2n) {
          @media(min-width: 1200px) {
            max-width: 52.56%;
            flex: 0 0 52.56%;
          }
        }
        &:nth-child(2n+1) {
          @media(min-width: 1200px) {
            max-width: 47.44%;
            flex: 0 0 47.44%;
          }
        }
      }
    }
    .about {
      &__image {
        margin-bottom: 30px;
        img {
          @media(min-width: 1200px) {
            margin-left: auto;
          }
        }
      }
      &__content {
        margin-bottom: 30px;
        font-size: 13px;
        line-height: 24px;
        h2 {
          font-size: 17px;
          text-transform: uppercase;
          color: color(typography, 5);
          position: relative;
          padding-left: 33%;
          line-height: 1.3;
          margin-bottom: 24px;
          &:before {
            content: '';
            background-color: color(typography, 5);
            width: 11.5%;
            height: 3px;
            left: 0;
            position: absolute;
            top: 10px;
            z-index: 2;
          }
          &:after {
            content: '';
            background-color: color(typography, 7);
            width: 27%;
            height: 1px;
            left: 0;
            position: absolute;
            top: 11px;
            z-index: 1;
          }
        }
        p {
          margin-bottom: 18px;
          .green {
            color: color(typography, 7);
            font-size: 15px;
            font-weight: 600;
          }
        }
        ul {
          margin-bottom: 20px;
          padding-left: 17px;
          li {
            list-style: disc;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
  // contact page
  &-contact {
    padding-top: 60px;
    padding-bottom: 60px;

    .contact {
      &__info {
        ul {
          li {
            margin-bottom: 15px;

            strong {
              display: block;
              margin-bottom: 5px;
            }
            i {
              margin-right: 10px;
            }

            a {
              color: color(typography, 3);
            }
          }
        }
      }

      &__map {
        margin-top: 30px;

        h2 {
          font-size: 20px;
          padding-bottom: 6px;
          margin-bottom: 20px;
          border-bottom: 2px dotted rgba(color(typography, 3), .5);
        }

        iframe {
          height: 520px;
          width: 100%;
        }
      }

      &__form {
        @media(max-width: 767px) {
          margin-top: 30px;
        }

        h2 {
          font-size: 20px;
          padding-bottom: 6px;
          margin-bottom: 20px;
          border-bottom: 2px dotted rgba(color(typography, 3), .5);
        }

        .form-control {
          border-radius: 0;

          &:focus {
            box-shadow: none;
          }
        }

        .code-sec {
          margin-top: 20px;
          max-width: 200px;
        }

        .btn-default {
          border-radius: 0;
          background-color: color(typography, 5);
          color: color(typography, 1);
          margin-top: 20px;
          min-width: 200px;
          text-transform: uppercase;
        }
      }
    }
  }
  &-faq {
    background-color: color(typography, 1);
    padding: 50px 0 20px;
    .accordion {
      .card {
        border-radius: 0;
        margin-bottom: 10px;
        border: 1px solid rgba(color(typography, 5), 1);
        &-header {
          margin-bottom: 0;
          border: none;
          padding: 0;
          .btn {
            display: block;
            padding-left: 15px;
            background-color: color(typography, 5);
            color: color(typography, 1);
            font-weight: 600;
            width: 100%;
            text-align: left;
            &:after {
              content: '-';
              position: absolute;
              right: 20px;
              transition: none;
            }
            &.collapsed {
              &:after {
                content: '+';
              }
            }
          }
        }
        &-body {
          padding: 15px;
        }
      }
    }
    .faq {
      &-right {
        @media(min-width: 992px) {
          padding-left: 30px;
        }

        @media(min-width: 1200px) {
          padding-left: 50px;
        }
      }
      &-rtcontent {
        // color: color(typography,7);
        font-weight: 600;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
  }
  &-testimonial {
    padding-top: 50px;
    padding-bottom: 30px;
    .title-border-left {
      max-width: 650px;
      margin-bottom: 34px;
    }

    .card-columns {
      @media (min-width: 576px) {
        column-count: 2;
      }
      @media (min-width: 768px) {
        column-count: 2;
      }
      @media (min-width: 992px) {
        // column-count: 3;
      }
      .card {
        margin-bottom: 20px;
        border: 1px solid rgba(color(typography, 3), .2);
        font-size: 14px;
        text-align: center;
        &-body {
          box-shadow: 0 0 14px rgba(color(typography, 3), .2);
          span.rating {
            margin-bottom: 8px;
            display: block;
            color: #ffd600;
          }
          .name {
            &:before {
              content: '-';
              margin-right: 10px;
            }
          }
        }
        &-image {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          margin: 0 auto 15px auto;
          overflow: hidden;
        }
        &-title {
          padding-left: 24px;
          position: relative;
          font-size: 16px;

          &:before {
            // content: '';
            // background-color: rgba(color(typography,3), 0.3);
            // height: 1px;
            // width: 15px;
            // position: absolute;
            // left: 0;
            // top: 15px;
          }
        }
      }
    }
    .testimonial {
      &-item {
        padding-left: 30px;
        background-image: url('../images/grey-quote-left.png');
        background-repeat: no-repeat;
        background-position-y: 4px;
        background-position-x: left;
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 30px;
        h5 {
          color: color(typography, 7);
          font-size: 15px;
          margin-bottom: 20px;
        }
        .date,
        .name {
          color: #25408f;
          display: inline-block;
          font-size: 15px;
          font-weight: 600;
        }
        .date {
          background-image: url('../images/icon-testimonial.png');
          padding-right: 50px;
          background-repeat: no-repeat;
          background-position: top right;
          padding-top: 30px;
        }
      }
    }

  }
}

.inner-titlebar {
  background: color(typography, 10) url('../images/titlebar-border.png') repeat-x center left;
  color: color(typography, 1);
  position: relative;
  min-height: 115px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  
  &:before,
  &:after {
    content: '';
    background-color: #daa023;
    height: 2px;
    width: 100%;
    left: 0;
    position: absolute;
    top: 10px;
  }
  &:after {
    top: auto;
    bottom: 10px;
  }
  h2 {
    background-color: color(typography, 10);
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    display: inline-block;
    min-width: 100px;
    padding-right: 20px;
  }
}

.title-border-left {

  h2 {
    font-size: 17px;
    text-transform: uppercase;
    color: color(typography, 5);
    position: relative;
    padding-left: 33%;
    line-height: 1.3;
    margin-bottom: 24px;
    &:before {
      content: '';
      background-color: color(typography, 5);
      width: 11.5%;
      height: 3px;
      left: 0;
      position: absolute;
      top: 10px;
      z-index: 2;
    }
    &:after {
      content: '';
      background-color: color(typography, 7);
      width: 27%;
      height: 1px;
      left: 0;
      position: absolute;
      top: 11px;
      z-index: 1;
    }
  }

}
.useful-links {
  padding-top: 65px;
  padding-bottom: 65px;
  @media(max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  [class*="col-"] {
    &:nth-child(2n+1) {
      @media(min-width: 992px) {
        max-width: 53%;
        flex: 0 0 53%;
      }
    }
    &:nth-child(2n) {
      @media(min-width: 992px) {
        max-width: 47%;
        flex: 0 0 47%;
      }
    }
  }
}
.useful {
  &-linkright {
    @media(min-width: 992px) {
      padding-left: 30px;
    }

    @media(min-width: 1200px) {
      padding-left: 40px;
    }
  }
}
.useful-items {
  margin-bottom: 20px;
  .links {
    color: #25408f;
    font-weight: 600;
    padding-left: 15px;
    background: url('../images/useful-link-arrow.png') no-repeat left top;
    display: inline-block;
    background-position-y: 8px;
  }
}
.useful-link-left {
  font-size: 13px;
  p {
    span {
      font-size: 15px;
      font-weight: 600;
      color: color(typography, 7);
    }
  }
}

.section-driving {
  padding-top: 65px;
  padding-bottom: 65px;
  @media(max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .driving {
    &-content {
      ul {
        padding-left: 30px;
        margin-bottom: 30px;
        li {
          list-style: disc;
        }
      }
    }
  }
  .content-left {
    ul {
      padding-left: 30px;
      margin-bottom: 30px;
      li {
        list-style: disc;
        margin-bottom: 3px;
      }
    }
  }
}

.content-block {
  ul {
    padding-left: 24px;
    margin-bottom: 20px;
    li {
      margin-bottom: 15px;
      list-style: disc;
    }
  }
}

.form-driving {
  // background-color: #dadada;
  background-color: color(typography, 1);
  // padding: 20px;
  h3 {
    border-bottom: 1px solid #ced4da;
    padding-bottom: 12px;
    margin-bottom: 20px;
  }
  label {
    sup {
      color: #d83131;
      font-size: 16px;
    }
  }
  .row {
    margin-left: -3px;
    margin-right: -3px;
    [class^="col-"] {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  .form-control {
    border: 1px solid #f1f1f1;
    background-color: #fcfcfc;
    border-radius: 0;
    &:focus {
      box-shadow: none;
    }

  }
  .rad-btn {
    display: flex;
    flex-wrap: wrap;
    .form-check {
      margin-right: 10px;
    }
  }
}

.form-section {
  h3 {
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 1px dashed #ced4da;
  }
  .form-driving {
    @media(min-width: 992px) {
      padding-left: 40px;
      border-left: 1px dashed #ced4da;
    }
  }
  .content-left {
    @media(min-width: 992px) {
      padding-right: 10px;
    }
  }
}