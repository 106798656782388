
.header-top {
    background-color: color(typography, 5);
    color: color(typography,1);
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 13px;
    .header-address {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin-left: -15px;
            margin-right: -15px;
            @media(min-width: 768px) {
                justify-content: flex-end;
            }
            li {
                padding-left: 15px;
                padding-right: 15px;
                @media(max-width: 767px) {
                    margin-top: 3px;
                    margin-bottom: 3px;
                }
                a {
                    color: color(typography, 1);
                }
                &.phone {
                    .icon-phone {
                        background-image: url('../images/phone.png');
                        background-repeat: no-repeat;
                        background-position-x: 0;
                        padding-left: 30px;
                        background-position-y: 3px;
                    }
                }
                &.email {
                    .icon-email {
                        background-image: url('../images/envelop.png');
                        background-repeat: no-repeat;
                        background-position-x: 0;
                        padding-left: 24px;
                        background-position-y: 6px;
                    }
                    a {
                        
                    }
                }
            }
        }
    }
}

.navbar {
    &[class*="navbar-expand-"] {
        background-color: color(typography, 1);
        padding: 0;
        position: relative;
        box-shadow: 0 1px 15px rgba(color(typography, 3), 0.3);

        @media(max-width: 991px) {
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .navbar-toggler {
            width: 30px;
            height: 20px;
            position: relative;
            margin: 0;
            padding: 0;
            outline: none;
            border: none;
            @include transition(.5s ease-in-out);
            cursor: pointer;

            &:focus {
                outline: none;
            }

            .navbar-toggle-icon {
                background: color(typography, 5);
                display: block;
                // position: absolute;
                height: 3px;
                width: 100%;
                border-radius: 0;
                opacity: 1;
                left: 0;
                margin-bottom: 6px;
                transform: rotate(0deg);
                @include transition(.25s ease-in-out);

                &:nth-child(1) {
                    top: 0px;
                }

                &:nth-child(2),
                &:nth-child(3) {
                    top: 10px;
                    top: 8px;
                }
            }

            &.on {
                .navbar-toggle-icon {
                    &:nth-child(1) {
                        top: 11px;
                        width: 0%;
                        left: 50%;
                    }

                    &:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    &:nth-child(3) {
                        transform: rotate(-45deg);
                        margin-top: -8px;

                    }

                }
            }
        }

        .navbar {
            &-collapse {
                @media(max-width: 991px) {
                    padding-top: 12px;
                    margin-top: 15px;
                    border-top: 1px solid rgba(color(typography, 5), .3);
                }
            }
            &-brand {
                padding-top: 26px;
                padding-bottom: 27px;
                max-width: 350px;

                @media(max-width: 1199px) {
                    max-width: 150px;
                }
                @media(max-width: 991px) {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

            }

            &-nav {
                @media(min-width: 992px) {
                    align-items: center;
                }

                li {
                    transition: all 1s ease;

                    @media(max-width: 991px) {
                        margin-bottom: 3px;
                    }

                    &:not(:last-child) {
                        @media(min-width: 992px) {
                            margin-right: 20px;
                        }

                        @media(min-width: 1200px) {
                            // margin-right: 30px;
                        }
                    }


                    a {
                        font-weight: 600;
                        font-size: 14px;
                        display: block;
                        color: color(typography, 5);

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            color: color(typography, 3);
                        }
                    }

                    .dropdown-toggle {
                        &:after {
                            content: '\f107';
                            border: none;
                            font-family: 'FontAwesome';
                            vertical-align: middle;
                            @media(min-width: 1200px) {
                                background: url('../images/menu-arrow.png') no-repeat;
                                color: transparent;
                                position: relative;
                                top: 7px;
                            }

                            @media(max-width: 991px) {
                                display: none;
                            }
                        }

                        .glyphicon {
                            @media(max-width: 991px) {
                                display: none;
                            }

                        }
                    }


                    .sub-menu,
                    .dropdown-menu {
                        background-color: rgba(color(typography, 5), 1);
                        position: absolute;
                        padding: 0;
                        border-radius: 0;
                        min-width: 240px;
                        max-height: 350px;
                        overflow-y: auto;
                        @media(max-width: 991px) {
                            background-color: rgba(color(typography, 5), 1);
                        }

                        li {
                            margin-right: 0;
                            @media(max-width: 991px) {
                                margin-bottom: 0;
                            }

                            a {
                                color: color(typography, 1);
                                padding: 6px 10px;
                                font-size: 14px;
                                display: block;
                                border-bottom: 1px solid rgba(color(typography, 1), 0.15);
                            }
                        }
                    }

                    &.dropdown,
                    &.menu-item-has-children {
                        position: relative;

                        &:after {
                            // @media(max-width: 991px) {
                            //     content: '\f078';
                            //     font-family: "FontAwesome";
                            //     color: color(typography, 3);
                            //     font-size: 11px;
                            // }

                        }

                        &:hover {
                            &:after {
                                @media(min-width: 992px) {
                                    color: color(typography, 2);
                                }
                            }
                        }



                        .drop-toggler {
                            color: color(typography, 5);
                            font-size: 11px;

                            @media(min-width: 992px) {
                                display: none;
                            }

                            @media(max-width: 991px) {
                                position: absolute;
                                right: 0;
                                width: 32px;
                                right: 0;
                                height: 32px;
                                line-height: 32px;
                                top: 0;
                                text-align: center;
                            }
                        }

                        &:hover {
                            .sub-menu {
                                display: block;
                            }
                        }
                    }

                }


            }

        }

    }
}
// body {
//     &.home-page {
//         .navbar {
//             &[class*="navbar-expand-"] {
//                 @media(min-width: 992px) {
//                     background-color: transparent;
//                 }
//                 .navbar-nav {
//                     li a {
//                         color: color(typography, 1);
//                     }
//                 }
//             }
            
//         }
//     }
// }

