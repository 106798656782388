@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
.rotate {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

label {
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
  transition: 0.2s ease-out; }

.navbar-toggler span {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }

button {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  button:after {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

.btn {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  .btn:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

a {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease; }
  a:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

input[type="radio"] + label {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  input[type="radio"] + label::before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  input[type="radio"] + label::after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

input[type="checkbox"] + label {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  input[type="checkbox"] + label:before {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  input[type="checkbox"] + label:after {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
  background: #fff url(../images/ajax-loader.gif) center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url(../fonts/slick.eot);
  src: url(../fonts/slick.eot?#iefix) format("embedded-opentype"), url(../fonts/slick.woff) format("woff"), url(../fonts/slick.ttf) format("truetype"), url(../fonts/slick.svg#slick) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

body {
  color: #282829;
  background-color: #FFFFFF;
  line-height: 1.6;
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  font-family: "Open Sans", sans-serif;
  overflow-x: hidden; }

.img-100 {
  width: 100%; }

small {
  font-size: 14px; }

b,
strong {
  font-weight: bold; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

ul.list-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  ul.list-inline li {
    display: inline-block; }

img {
  display: block;
  max-width: 100%;
  height: auto; }
  img.img-center {
    margin-left: auto;
    margin-right: auto; }
  img.full-width {
    width: 100%; }

a {
  text-decoration: none; }
  a:hover, a:focus {
    text-decoration: none;
    color: inherit; }

.btn {
  text-decoration: none; }
  .btn:hover {
    text-decoration: none; }

a:hover::before, a:hover::after {
  text-decoration: none; }

a:focus {
  outline: none; }

.btn:focus {
  outline: none; }

.btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none; }

.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: inherit; }

p {
  margin: 0 0 1rem 0; }
  p:empty {
    display: none; }
  p:last-child {
    margin-bottom: 0; }

.screen-reader-text {
  display: none; }

h1 {
  font-size: 40px;
  font-weight: 600; }

h2 {
  font-size: 30px;
  line-height: 38px;
  font-weight: 600; }

h3 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600; }

h4 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600; }

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }

img {
  display: block;
  max-width: 100%;
  height: auto; }
  img .aligncenter {
    float: none;
    margin: 0 auto 20px; }
  img .alignright {
    float: right;
    margin: 0 0 20px 20px; }
  img .alignleft {
    float: left;
    margin: 0 20px 20px 0; }

.slick-slide:focus {
  outline: none; }

.btn {
  border-radius: 0;
  font-size: 15px;
  padding: 10px 30px; }
  .btn-blue {
    background-color: #25408f;
    color: #FFFFFF;
    font-weight: 700;
    border: 1px solid #25408f; }
    .btn-blue:hover {
      background-color: #FFFFFF;
      color: #fcb316;
      border: 1px solid #fcb316; }
  .btn-white {
    background-color: #FFFFFF;
    color: #fcb316;
    border: 1px solid #fcb316;
    font-weight: 600;
    margin-top: 26px; }
    .btn-white:hover {
      background-color: #25408f;
      color: #FFFFFF;
      border: 1px solid #25408f; }
  .btn-green {
    background-color: #41ad48;
    color: #FFFFFF;
    border: 1px solid #41ad48;
    font-weight: 600;
    margin-top: 26px; }
    .btn-green:hover {
      background-color: #FFFFFF;
      color: #41ad48;
      border: 1px solid #41ad48; }

.header-top {
  background-color: #25408f;
  color: #FFFFFF;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 13px; }
  .header-top .header-address ul {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    @media (min-width: 768px) {
      .header-top .header-address ul {
        justify-content: flex-end; } }
    .header-top .header-address ul li {
      padding-left: 15px;
      padding-right: 15px; }
      @media (max-width: 767px) {
        .header-top .header-address ul li {
          margin-top: 3px;
          margin-bottom: 3px; } }
      .header-top .header-address ul li a {
        color: #FFFFFF; }
      .header-top .header-address ul li.phone .icon-phone {
        background-image: url("../images/phone.png");
        background-repeat: no-repeat;
        background-position-x: 0;
        padding-left: 30px;
        background-position-y: 3px; }
      .header-top .header-address ul li.email .icon-email {
        background-image: url("../images/envelop.png");
        background-repeat: no-repeat;
        background-position-x: 0;
        padding-left: 24px;
        background-position-y: 6px; }

.navbar[class*="navbar-expand-"] {
  background-color: #FFFFFF;
  padding: 0;
  position: relative;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.3); }
  @media (max-width: 991px) {
    .navbar[class*="navbar-expand-"] {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px;
      padding-bottom: 10px; } }
  .navbar[class*="navbar-expand-"] .navbar-toggler {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -ms-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer; }
    .navbar[class*="navbar-expand-"] .navbar-toggler:focus {
      outline: none; }
    .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon {
      background: #25408f;
      display: block;
      height: 3px;
      width: 100%;
      border-radius: 0;
      opacity: 1;
      left: 0;
      margin-bottom: 6px;
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -ms-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out; }
      .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(1) {
        top: 0px; }
      .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(2), .navbar[class*="navbar-expand-"] .navbar-toggler .navbar-toggle-icon:nth-child(3) {
        top: 10px;
        top: 8px; }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(1) {
      top: 11px;
      width: 0%;
      left: 50%; }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(2) {
      transform: rotate(45deg); }
    .navbar[class*="navbar-expand-"] .navbar-toggler.on .navbar-toggle-icon:nth-child(3) {
      transform: rotate(-45deg);
      margin-top: -8px; }
  @media (max-width: 991px) {
    .navbar[class*="navbar-expand-"] .navbar-collapse {
      padding-top: 12px;
      margin-top: 15px;
      border-top: 1px solid rgba(37, 64, 143, 0.3); } }
  .navbar[class*="navbar-expand-"] .navbar-brand {
    padding-top: 26px;
    padding-bottom: 27px;
    max-width: 350px; }
    @media (max-width: 1199px) {
      .navbar[class*="navbar-expand-"] .navbar-brand {
        max-width: 150px; } }
    @media (max-width: 991px) {
      .navbar[class*="navbar-expand-"] .navbar-brand {
        padding-top: 10px;
        padding-bottom: 10px; } }
  @media (min-width: 992px) {
    .navbar[class*="navbar-expand-"] .navbar-nav {
      align-items: center; } }
  .navbar[class*="navbar-expand-"] .navbar-nav li {
    transition: all 1s ease; }
    @media (max-width: 991px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li {
        margin-bottom: 3px; } }
    @media (min-width: 992px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li:not(:last-child) {
        margin-right: 20px; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li a {
      font-weight: 600;
      font-size: 14px;
      display: block;
      color: #25408f; }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:focus {
        outline: none; }
      .navbar[class*="navbar-expand-"] .navbar-nav li a:hover {
        color: #000000; }
    .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
      content: '\f107';
      border: none;
      font-family: 'FontAwesome';
      vertical-align: middle; }
      @media (min-width: 1200px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
          background: url("../images/menu-arrow.png") no-repeat;
          color: transparent;
          position: relative;
          top: 7px; } }
      @media (max-width: 991px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle:after {
          display: none; } }
    @media (max-width: 991px) {
      .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-toggle .glyphicon {
        display: none; } }
    .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu,
    .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu {
      background-color: #25408f;
      position: absolute;
      padding: 0;
      border-radius: 0;
      min-width: 240px;
      max-height: 350px;
      overflow-y: auto; }
      @media (max-width: 991px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu,
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu {
          background-color: #25408f; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li,
      .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li {
        margin-right: 0; }
        @media (max-width: 991px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li,
          .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li {
            margin-bottom: 0; } }
        .navbar[class*="navbar-expand-"] .navbar-nav li .sub-menu li a,
        .navbar[class*="navbar-expand-"] .navbar-nav li .dropdown-menu li a {
          color: #FFFFFF;
          padding: 6px 10px;
          font-size: 14px;
          display: block;
          border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
    .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children {
      position: relative; }
      @media (min-width: 992px) {
        .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown:hover:after, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children:hover:after {
          color: #FF0000; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
        color: #25408f;
        font-size: 11px; }
        @media (min-width: 992px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
            display: none; } }
        @media (max-width: 991px) {
          .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown .drop-toggler, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children .drop-toggler {
            position: absolute;
            right: 0;
            width: 32px;
            right: 0;
            height: 32px;
            line-height: 32px;
            top: 0;
            text-align: center; } }
      .navbar[class*="navbar-expand-"] .navbar-nav li.dropdown:hover .sub-menu, .navbar[class*="navbar-expand-"] .navbar-nav li.menu-item-has-children:hover .sub-menu {
        display: block; }

@media (min-width: 1200px) {
  .container {
    max-width: 1170px; } }

.slick-slider {
  margin: 0 -4px; }

.slick-slide {
  padding: 0 4px; }

.section-welcome {
  background-color: #FFFFFF;
  padding: 42px 0; }
  @media (min-width: 1200px) {
    .section-welcome .container {
      padding-left: 0;
      padding-right: 0; } }
  @media (min-width: 1200px) {
    .section-welcome .image-wrap {
      width: 620px; } }
  @media (min-width: 1200px) {
    .section-welcome .image-wrap img {
      max-width: initial;
      margin-left: -28px; } }
  .section-welcome .title-wrap h3 {
    position: relative;
    color: #25408f; }
  .section-welcome .content-wrap {
    line-height: 30px; }
    @media (max-width: 991px) {
      .section-welcome .content-wrap {
        margin-top: 30px; } }
  .section-welcome h3 {
    font-size: 20px; }
  .section-welcome p span,
  .section-welcome p a {
    color: #25408f; }

.section-carousel {
  background-color: #FFFFFF;
  padding: 25px 0 40px; }
  .section-carousel .container {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0; }

.slide-slider .slick-arrow {
  height: 75px;
  width: 75px;
  background-size: contain;
  z-index: 10;
  background-repeat: no-repeat;
  background-position: center; }
  .slide-slider .slick-arrow:before {
    content: ''; }

.slide-slider .slick-prev {
  background-image: url(../images/arrow-prev-white.png);
  left: 15px; }

.slide-slider .slick-next {
  background-image: url(../images/arrow-next-white.png);
  right: 15px; }

.slide-wrap {
  position: relative;
  overflow: hidden; }
  .slide-wrap .overlay {
    background-color: rgba(0, 23, 39, 0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2; }

.slide-image img {
  width: 100%; }

.section-crossroad {
  background-color: #eff2f5;
  background-repeat: repeat-y;
  background-position-x: 52%;
  background-size: contain;
  padding-bottom: 50px; }
  .section-crossroad .crossroad-choose h4 {
    font-size: 18px;
    font-weight: 600;
    color: #25408f;
    letter-spacing: -0.055rem; }
  .section-crossroad .crossroad-choose ul li {
    position: relative;
    padding-left: 20px; }
    .section-crossroad .crossroad-choose ul li:before {
      background-color: #596ca3;
      content: '';
      height: 10px;
      width: 10px;
      position: absolute;
      left: 0;
      top: 8px; }
    .section-crossroad .crossroad-choose ul li:not(:last-child) {
      margin-bottom: 10px; }
  .section-crossroad .crossroad-order {
    font-size: 18px;
    font-weight: 600;
    color: #41ad48;
    text-align: center; }
    @media (max-width: 991px) {
      .section-crossroad .crossroad-order {
        margin-top: 30px; } }
    .section-crossroad .crossroad-order h2 {
      color: #25408f;
      font-weight: 600;
      font-size: 20px; }
    .section-crossroad .crossroad-order img {
      margin: 20px auto; }
    .section-crossroad .crossroad-order .btn {
      min-width: 215px;
      font-size: 18px; }

.testimonial {
  padding-top: 23px;
  padding-bottom: 33px; }
  .testimonial .title-wrap h2 {
    font-size: 20px;
    color: #41ad48;
    position: relative; }
    .testimonial .title-wrap h2:before, .testimonial .title-wrap h2:after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 0; }
    .testimonial .title-wrap h2:before {
      background-color: #25408f;
      max-width: 340px;
      height: 2px;
      z-index: 1; }
    .testimonial .title-wrap h2:after {
      background-color: #b8c5d1;
      height: 1px;
      bottom: 1px; }
    .testimonial .title-wrap h2 .icon-testt {
      background-image: url("../images/icon-testimonial.png");
      height: 35px;
      width: 40px;
      display: inline-block;
      background-repeat: no-repeat;
      font-size: 0;
      margin-left: 40px;
      top: -30px;
      position: relative; }
  .testimonial-more {
    color: #41ad48;
    font-weight: 600;
    font-style: normal; }
  .testimonial-wrapper {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 35px;
    font-style: italic; }
  .testimonial .slick-arrow {
    height: 40px;
    width: 40px;
    top: 25px; }
    .testimonial .slick-arrow:before {
      content: ''; }
  .testimonial .slick-prev {
    background-image: url("../images/testimonial-prev.png");
    left: -77px; }
  .testimonial .slick-next {
    background-image: url("../images/testimonial-next.png");
    right: -77px; }
  .testimonial .client-name {
    font-size: 15px;
    font-weight: 600; }

.testimonial-slider .slick-dots li button {
  border-radius: 50%;
  height: 15px;
  width: 15px; }
  .testimonial-slider .slick-dots li button:before {
    content: ''; }

.testimonial-slider .slick-dots li:nth-child(2n+1) button {
  background-color: #ffc907; }

.testimonial-slider .slick-dots li:nth-child(2n) button {
  background-color: #d2232a; }

.testimonial-slider .slick-dots li:nth-child(3n) button {
  background-color: #41ad48; }

.section-projects {
  padding-top: 60px;
  padding-bottom: 30px; }
  .section-projects .project__wrap {
    margin-bottom: 30px; }
    .section-projects .project__wrap figure {
      margin-bottom: 0;
      height: 340px;
      background-size: cover;
      background-position: center; }
      .section-projects .project__wrap figure a {
        display: block;
        height: 100%;
        width: 100%; }
  .section-projects .project__title {
    padding: 10px 10px 0 10px;
    border: 1px solid #ddd;
    text-align: center; }
    .section-projects .project__title h3 {
      font-size: 18px;
      font-weight: 600; }
      .section-projects .project__title h3 a {
        color: currentColor; }
        .section-projects .project__title h3 a:hover {
          color: #25408f; }

.section-about {
  padding-top: 65px;
  padding-bottom: 30px; }
  @media (max-width: 991px) {
    .section-about {
      padding-top: 40px;
      padding-bottom: 0; } }
  @media (min-width: 1200px) {
    .section-about .row [class*="col-"]:nth-child(2n) {
      max-width: 52.56%;
      flex: 0 0 52.56%; } }
  @media (min-width: 1200px) {
    .section-about .row [class*="col-"]:nth-child(2n+1) {
      max-width: 47.44%;
      flex: 0 0 47.44%; } }
  .section-about .about__image {
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .section-about .about__image img {
        margin-left: auto; } }
  .section-about .about__content {
    margin-bottom: 30px;
    font-size: 13px;
    line-height: 24px; }
    .section-about .about__content h2 {
      font-size: 17px;
      text-transform: uppercase;
      color: #25408f;
      position: relative;
      padding-left: 33%;
      line-height: 1.3;
      margin-bottom: 24px; }
      .section-about .about__content h2:before {
        content: '';
        background-color: #25408f;
        width: 11.5%;
        height: 3px;
        left: 0;
        position: absolute;
        top: 10px;
        z-index: 2; }
      .section-about .about__content h2:after {
        content: '';
        background-color: #41ad48;
        width: 27%;
        height: 1px;
        left: 0;
        position: absolute;
        top: 11px;
        z-index: 1; }
    .section-about .about__content p {
      margin-bottom: 18px; }
      .section-about .about__content p .green {
        color: #41ad48;
        font-size: 15px;
        font-weight: 600; }
    .section-about .about__content ul {
      margin-bottom: 20px;
      padding-left: 17px; }
      .section-about .about__content ul li {
        list-style: disc;
        margin-bottom: 5px; }

.section-contact {
  padding-top: 60px;
  padding-bottom: 60px; }
  .section-contact .contact__info ul li {
    margin-bottom: 15px; }
    .section-contact .contact__info ul li strong {
      display: block;
      margin-bottom: 5px; }
    .section-contact .contact__info ul li i {
      margin-right: 10px; }
    .section-contact .contact__info ul li a {
      color: #000000; }
  .section-contact .contact__map {
    margin-top: 30px; }
    .section-contact .contact__map h2 {
      font-size: 20px;
      padding-bottom: 6px;
      margin-bottom: 20px;
      border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
    .section-contact .contact__map iframe {
      height: 520px;
      width: 100%; }
  @media (max-width: 767px) {
    .section-contact .contact__form {
      margin-top: 30px; } }
  .section-contact .contact__form h2 {
    font-size: 20px;
    padding-bottom: 6px;
    margin-bottom: 20px;
    border-bottom: 2px dotted rgba(0, 0, 0, 0.5); }
  .section-contact .contact__form .form-control {
    border-radius: 0; }
    .section-contact .contact__form .form-control:focus {
      box-shadow: none; }
  .section-contact .contact__form .code-sec {
    margin-top: 20px;
    max-width: 200px; }
  .section-contact .contact__form .btn-default {
    border-radius: 0;
    background-color: #25408f;
    color: #FFFFFF;
    margin-top: 20px;
    min-width: 200px;
    text-transform: uppercase; }

.section-faq {
  background-color: #FFFFFF;
  padding: 50px 0 20px; }
  .section-faq .accordion .card {
    border-radius: 0;
    margin-bottom: 10px;
    border: 1px solid #25408f; }
    .section-faq .accordion .card-header {
      margin-bottom: 0;
      border: none;
      padding: 0; }
      .section-faq .accordion .card-header .btn {
        display: block;
        padding-left: 15px;
        background-color: #25408f;
        color: #FFFFFF;
        font-weight: 600;
        width: 100%;
        text-align: left; }
        .section-faq .accordion .card-header .btn:after {
          content: '-';
          position: absolute;
          right: 20px;
          transition: none; }
        .section-faq .accordion .card-header .btn.collapsed:after {
          content: '+'; }
    .section-faq .accordion .card-body {
      padding: 15px; }
  @media (min-width: 992px) {
    .section-faq .faq-right {
      padding-left: 30px; } }
  @media (min-width: 1200px) {
    .section-faq .faq-right {
      padding-left: 50px; } }
  .section-faq .faq-rtcontent {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 30px; }

.section-testimonial {
  padding-top: 50px;
  padding-bottom: 30px; }
  .section-testimonial .title-border-left {
    max-width: 650px;
    margin-bottom: 34px; }
  @media (min-width: 576px) {
    .section-testimonial .card-columns {
      column-count: 2; } }
  @media (min-width: 768px) {
    .section-testimonial .card-columns {
      column-count: 2; } }
  .section-testimonial .card-columns .card {
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 14px;
    text-align: center; }
    .section-testimonial .card-columns .card-body {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.2); }
      .section-testimonial .card-columns .card-body span.rating {
        margin-bottom: 8px;
        display: block;
        color: #ffd600; }
      .section-testimonial .card-columns .card-body .name:before {
        content: '-';
        margin-right: 10px; }
    .section-testimonial .card-columns .card-image {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin: 0 auto 15px auto;
      overflow: hidden; }
    .section-testimonial .card-columns .card-title {
      padding-left: 24px;
      position: relative;
      font-size: 16px; }
  .section-testimonial .testimonial-item {
    padding-left: 30px;
    background-image: url("../images/grey-quote-left.png");
    background-repeat: no-repeat;
    background-position-y: 4px;
    background-position-x: left;
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 30px; }
    .section-testimonial .testimonial-item h5 {
      color: #41ad48;
      font-size: 15px;
      margin-bottom: 20px; }
    .section-testimonial .testimonial-item .date,
    .section-testimonial .testimonial-item .name {
      color: #25408f;
      display: inline-block;
      font-size: 15px;
      font-weight: 600; }
    .section-testimonial .testimonial-item .date {
      background-image: url("../images/icon-testimonial.png");
      padding-right: 50px;
      background-repeat: no-repeat;
      background-position: top right;
      padding-top: 30px; }

.inner-titlebar {
  background: #272727 url("../images/titlebar-border.png") repeat-x center left;
  color: #FFFFFF;
  position: relative;
  min-height: 115px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center; }
  .inner-titlebar:before, .inner-titlebar:after {
    content: '';
    background-color: #daa023;
    height: 2px;
    width: 100%;
    left: 0;
    position: absolute;
    top: 10px; }
  .inner-titlebar:after {
    top: auto;
    bottom: 10px; }
  .inner-titlebar h2 {
    background-color: #272727;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
    display: inline-block;
    min-width: 100px;
    padding-right: 20px; }

.title-border-left h2 {
  font-size: 17px;
  text-transform: uppercase;
  color: #25408f;
  position: relative;
  padding-left: 33%;
  line-height: 1.3;
  margin-bottom: 24px; }
  .title-border-left h2:before {
    content: '';
    background-color: #25408f;
    width: 11.5%;
    height: 3px;
    left: 0;
    position: absolute;
    top: 10px;
    z-index: 2; }
  .title-border-left h2:after {
    content: '';
    background-color: #41ad48;
    width: 27%;
    height: 1px;
    left: 0;
    position: absolute;
    top: 11px;
    z-index: 1; }

.useful-links {
  padding-top: 65px;
  padding-bottom: 65px; }
  @media (max-width: 991px) {
    .useful-links {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (min-width: 992px) {
    .useful-links [class*="col-"]:nth-child(2n+1) {
      max-width: 53%;
      flex: 0 0 53%; } }
  @media (min-width: 992px) {
    .useful-links [class*="col-"]:nth-child(2n) {
      max-width: 47%;
      flex: 0 0 47%; } }

@media (min-width: 992px) {
  .useful-linkright {
    padding-left: 30px; } }

@media (min-width: 1200px) {
  .useful-linkright {
    padding-left: 40px; } }

.useful-items {
  margin-bottom: 20px; }
  .useful-items .links {
    color: #25408f;
    font-weight: 600;
    padding-left: 15px;
    background: url("../images/useful-link-arrow.png") no-repeat left top;
    display: inline-block;
    background-position-y: 8px; }

.useful-link-left {
  font-size: 13px; }
  .useful-link-left p span {
    font-size: 15px;
    font-weight: 600;
    color: #41ad48; }

.section-driving {
  padding-top: 65px;
  padding-bottom: 65px; }
  @media (max-width: 991px) {
    .section-driving {
      padding-top: 30px;
      padding-bottom: 30px; } }
  .section-driving .driving-content ul {
    padding-left: 30px;
    margin-bottom: 30px; }
    .section-driving .driving-content ul li {
      list-style: disc; }
  .section-driving .content-left ul {
    padding-left: 30px;
    margin-bottom: 30px; }
    .section-driving .content-left ul li {
      list-style: disc;
      margin-bottom: 3px; }

.content-block ul {
  padding-left: 24px;
  margin-bottom: 20px; }
  .content-block ul li {
    margin-bottom: 15px;
    list-style: disc; }

.form-driving {
  background-color: #FFFFFF; }
  .form-driving h3 {
    border-bottom: 1px solid #ced4da;
    padding-bottom: 12px;
    margin-bottom: 20px; }
  .form-driving label sup {
    color: #d83131;
    font-size: 16px; }
  .form-driving .row {
    margin-left: -3px;
    margin-right: -3px; }
    .form-driving .row [class^="col-"] {
      padding-left: 3px;
      padding-right: 3px; }
  .form-driving .form-control {
    border: 1px solid #f1f1f1;
    background-color: #fcfcfc;
    border-radius: 0; }
    .form-driving .form-control:focus {
      box-shadow: none; }
  .form-driving .rad-btn {
    display: flex;
    flex-wrap: wrap; }
    .form-driving .rad-btn .form-check {
      margin-right: 10px; }

.form-section h3 {
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ced4da; }

@media (min-width: 992px) {
  .form-section .form-driving {
    padding-left: 40px;
    border-left: 1px dashed #ced4da; } }

@media (min-width: 992px) {
  .form-section .content-left {
    padding-right: 10px; } }

.site-footer {
  background-color: #eff2f5;
  background-color: #222222;
  color: #FFFFFF;
  padding-top: 30px; }
  .site-footer .footer-mainlogo img {
    max-width: 350px; }
  .site-footer .footer-logo img {
    margin-left: auto;
    margin-right: auto; }
  @media (min-width: 1200px) {
    .site-footer .footer-top [class*="col-"]:nth-child(1) {
      flex: 0 0 40%;
      max-width: 40%; } }
  @media (min-width: 1200px) {
    .site-footer .footer-top [class*="col-"]:nth-child(2) {
      flex: 0 0 30%;
      max-width: 30%; } }
  @media (min-width: 1200px) {
    .site-footer .footer-top [class*="col-"]:nth-child(3) {
      flex: 0 0 30%;
      max-width: 30%; } }
  @media (min-width: 1200px) {
    .site-footer .footer-top [class*="col-"]:not(:last-child) .footer-wrap {
      padding-right: 40px; } }
  .site-footer .footer-wrap {
    line-height: 28px;
    margin-bottom: 30px; }
    .site-footer .footer-wrap a {
      color: #FFFFFF; }
      .site-footer .footer-wrap a:hover {
        text-decoration: underline; }
    .site-footer .footer-wrap .open-hour i {
      color: #FFFFFF;
      font-size: 18px;
      margin-right: 5px;
      font-weight: 600;
      font-style: normal; }
    .site-footer .footer-wrap .open-hour span {
      color: #FFFFFF;
      font-weight: 400;
      font-style: normal; }
  .site-footer .footer-title {
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 12px;
    margin-bottom: 12px; }
  .site-footer .footer-contact li i {
    color: #FFFFFF;
    font-size: 18px;
    margin-right: 5px;
    font-weight: 600;
    font-style: normal; }
  .site-footer .footer-copyright {
    border-top: 1px solid #cccccc;
    margin-top: 32px;
    font-size: 13px;
    padding-top: 20px;
    padding-bottom: 20px; }
    .site-footer .footer-copyright a {
      color: #FFFFFF; }
      .site-footer .footer-copyright a:hover {
        text-decoration: underline; }

.section-hero {
  position: relative;
  overflow: hidden; }
  .section-hero .hero__slider .overlay, .section-hero .hero__inner .overlay {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2; }
  .section-hero .hero-item {
    position: relative; }
  .section-hero .hero-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 30px;
    color: #FFFFFF;
    position: absolute;
    bottom: 100px;
    bottom: 0;
    top: auto;
    height: 100%;
    align-items: flex-end;
    z-index: 5;
    font-size: 20px;
    font-weight: 600; }
    @media (max-width: 991px) {
      .section-hero .hero-wrapper {
        bottom: 0; } }
    @media (max-width: 767px) {
      .section-hero .hero-wrapper {
        font-size: 22px; } }
    .section-hero .hero-wrapper h2 {
      color: #FFFFFF;
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 30px;
      line-height: 1.6;
      text-shadow: 0px 4px 5px #000000; }
      @media (max-width: 767px) {
        .section-hero .hero-wrapper h2 {
          line-height: 1.2;
          font-size: 22px; } }
    @media (max-width: 767px) {
      .section-hero .hero-wrapper p br {
        display: none; } }
    .section-hero .hero-wrapper .btn {
      background-color: transparent;
      border: 2px solid #FFFFFF;
      color: #FFFFFF;
      border-radius: 0;
      padding: 10px 30px;
      font-weight: 600;
      margin-top: 10px;
      font-size: 25px;
      line-height: 1.1;
      text-shadow: 0px 4px 5px #000000; }
      .section-hero .hero-wrapper .btn:hover {
        background-color: #25408f;
        border: 2px solid #25408f;
        color: #FFFFFF; }
      .section-hero .hero-wrapper .btn i {
        margin-left: 15px; }
