.site-footer {
    background-color: #eff2f5;
    background-color: #222222;
    color: color(typography, 1);
    padding-top: 30px;
    .footer {
        &-mainlogo {
            img {
                max-width: 350px;
            }
        }
        &-logo {
            img {
                margin-left: auto;
                margin-right: auto;
            }
        }
        &-top {
            [class*="col-"] {
                &:nth-child(1) {
                    @media(min-width: 1200px) {
                        flex: 0 0 40%;
                        max-width: 40%;
                    }
                }
                &:nth-child(2) {
                    @media(min-width: 1200px) {
                        flex: 0 0 30%;
                        max-width: 30%;
                    }
                }
                &:nth-child(3) {
                    @media(min-width: 1200px) {
                        flex: 0 0 30%;
                        max-width: 30%;
                    }
                }
                &:not(:last-child) {
                    .footer {
                        &-wrap {
                            @media(min-width: 1200px) {
                                padding-right: 40px;
                            }
                        }
                    }
                }
            }
        }
        &-wrap {
            line-height: 28px;
            margin-bottom: 30px;
            a {
                color: color(typography, 1);
                &:hover {
                    // color: color(typography, 5);
                    text-decoration: underline;
                }
            }
            .open-hour {
                
                i {
                    color: color(typography, 1);
                    font-size: 18px;
                    margin-right: 5px;
                    font-weight: 600;
                    font-style: normal;
                    
                }
                span {
                    color: color(typography, 1);
                    font-weight: 400;
                    font-style: normal;
                }
            }
        }
        &-title {
            font-size: 20px;
            line-height: 28px;
            color: color(typography, 1);
            border-bottom: 1px solid color(typography, 1);
            padding-bottom: 12px;
            margin-bottom: 12px;
        }
        &-contact {
            li {
                i {
                    color: color(typography, 1);
                    font-size: 18px;
                    margin-right: 5px;
                    font-weight: 600;
                    font-style: normal;
                }
            }
        }


        &-copyright {
            border-top: 1px solid #cccccc;
            margin-top: 32px;
            font-size: 13px;
            padding-top: 20px;
            padding-bottom: 20px;
            a {
                color: color(typography, 1);
                &:hover {
                    // color: color(typography, 5);
                    text-decoration: underline;
                }
            }
        }
    }
}